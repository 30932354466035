import { gql } from '@apollo/client';

export const loginQuery = gql`
query login($username: String, $email: String, $phonenum: String, $password: String!) {
  auth(username: $username, email: $email, phonenum: $phonenum, password: $password) {
    id
    username
    email
    gender
    profileimg
    seller
    token
  }
}
`

export const registerMutation = gql`
mutation register(
  $username: String!,
  $password: String!,
  $confirmPassword: String!,
  $email: String!,
  $firstName: String!,
  $lastName: String!,
  $phonenum: String!,
  $gender: String!,
  $dateOfBirth: String!,
  $seller: Boolean!) {
  register(registerInput: {
    username: $username
    password: $password
    confirmPassword: $confirmPassword
    email: $email
    firstName: $firstName
    lastName: $lastName
    phonenum: $phonenum
    gender: $gender
    dateOfBirth: $dateOfBirth
    seller: $seller
  }){
    id
    email
    phonenum
    username
    profileimg
    token
    sellerAccountUrl
  }
}
`

export const createPostMutation = gql`
mutation createPost(
  $private: Boolean!,
  $heading: String!,
  $body: String!,
  $postMedia: [PostmediaInput]!,
  $topics: [String]!,
  $selling: Boolean!,
  $price: Float
  ) {
  createPost(
    private: $private,
    heading: $heading,
    body: $body,
    postMedia: $postMedia,
    topics: $topics,
    selling: $selling,
    price: $price
    ) {
      id
      createdAt
      mediaTokens
      user {
        username
        profileimg
      }
  }
}
`

export const deletePostMutation = gql`
mutation deletePost($id: ID!) {
  deletePost(id: $id)
}
`
export const createCommentMutation = gql`
mutation createComment($postID: ID!, $body: String!) {
  createComment(postID: $postID, body: $body) {
    id
    private
    heading
    body
    postMedia {
      fileName
      mediaType
    }
    createdAt
    user {
      username
      profileimg
    }
    comments {
      id
      user {
        username
      }
      body
      createdAt
    }
    likes {
      user {
        username
      }
      createdAt
    }
    likeCount
    commentCount
    selling
    sku
    price
  }
}
`
export const deleteCommentMutation = gql`
mutation deleteComment($postID: ID!, $commentID: ID!) {
  deleteComment(postID: $postID, commentID: $commentID) {
    id
    body
    user {
      username
      email
    }
  }
}
`
export const likePostMutation = gql`
mutation likePost($postID: ID!) {
  likePost(postID: $postID) {
    id
    private
    heading
    body
    postMedia {
      fileName
      mediaType
    }
    createdAt
    user {
      username
      profileimg
    }
    comments {
      id
      user {
        username
      }
      body
      createdAt
    }
    likes {
      user {
        username
      }
      createdAt
    }
    likeCount
    commentCount
    selling
    sku
    price
  }
}
`

export const getFollowingQuery = gql`
query getFollowing {
  getFollowing {
    id
    username
    email
    profileimg
  }
}
`

export const getFeedQuery = gql`
query getFeed {
  getFeed {
    id
    private
    heading
    body
    postMedia {
      fileName
      mediaType
    }
    createdAt
    user {
      username
      profileimg
    }
    comments {
      id
      user {
        username
      }
      body
      createdAt
    }
    likes {
      user {
        username
      }
      createdAt
    }
    likeCount
    commentCount
    selling
    sku
    price
  }
}`

export const getProfileFeedQuery = gql`
query getProfileFeed($username: String!) {
  getProfileFeed(username: $username) {
    id
    private
    heading
    body
    postMedia {
      fileName
      mediaType
    }
    createdAt
    user {
      username
      profileimg
    }
    comments {
      id
      user {
        username
      }
      body
      createdAt
    }
    likes {
      user {
        username
      }
      createdAt
    }
    likeCount
    commentCount
    selling
    sku
    price
  }
}`

export const isFollowingQuery = gql`
query isFollowing($username: String!) {
  isFollowing(username: $username)
}`

export const getUserInfoQuery = gql`
query getUserInfo($username: String!) {
  getUserInfo(username: $username) {
    profileimg
    postCount
 		followerCount
    followingCount
  }
}`

export const followUserMutation = gql`
mutation followUser($username: String!) {
  followUser(username: $username)
}`

export const addBookmarkMutation = gql`
mutation addBookmark($postID: ID!) {
  addBookmark(postID: $postID) {
    user {
      username
      email
    }
    post {
      id
      private
      heading
      likeCount
      commentCount
      topics
      body
      user {
        username
      }
      postMedia {
        fileName
        mediaType
      }
      comments {
        user {
          username
        }
        createdAt
        body
      }
      likes {
        user {
          username
        }
      }
    }
  }
}`

export const deleteBookmarkMutation = gql`
mutation deleteBookmark($bookmarkID: ID!) {
  deleteBookmark(bookmarkID: $bookmarkID)
}`

export const onboardUserMutation = gql`
mutation onboardUser {
  onboardUser {
    token
    sellerAccountUrl
  }
}`

export const createOrderMutation = gql`
mutation createOrder($postID: ID!) {
  createOrder(postID: $postID) {
    intentSecret
    orderID
  }
}`

export const getOrderQuery = gql`
query getOrder($orderID: ID!) {
  getOrder(orderID: $orderID) {
    id
    post {
      heading
    }
    paymentIntent {
      amount
      status
      createdAt
      last4
      cardBrand
    }
  }
}`

export const getOrdersQuery = gql`
query getOrders {
  getOrders {
    id
    post {
      heading
    }
    paymentIntent {
      amount
      status
      createdAt
      last4
      cardBrand
    }
  }
}`
