import { Order } from './stripe';

export interface RegisterInput {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  phonenum: string;
  dateOfBirth: string;
  gender: string;
  seller: boolean;
}

export const registerInputDefault: RegisterInput = {
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
  phonenum: '',
  dateOfBirth: '',
  gender: '',
  seller: false,
};

export interface User {
  id: string;
  seller: boolean;
  email: string;
  isVerified: boolean;
  official: boolean;
  firstName: string;
  lastName: string;
  phonenum: string;
  username: string;
  profileimg: string;
  dateOfBirth: string;
  gender: string;
  createdAt: string;
  token: string;
  followers: User[];
  following: User[];
  postCount: number;
  followerCount: number;
  followingCount: number;
  sellerAccountUrl: string;
  orders: Order[];
}

export interface UserState {
  currentUser: User;
  profileUser: User;
  loaded: boolean;
  loading: boolean;
  hasError: boolean;
  errors: any;
  loggedIn: boolean;
  isFollowing: boolean;
}

export enum UserInfoType {
  Current = 1,
  Profile,
}

export const emptyUser: User = {
  id: '',
  seller: false,
  email: '',
  isVerified: false,
  official: false,
  firstName: '',
  lastName: '',
  phonenum: '',
  username: '',
  profileimg: '',
  dateOfBirth: '',
  gender: '',
  createdAt: '',
  token: '',
  followers: [],
  following: [],
  postCount: 0,
  followerCount: 0,
  followingCount: 0,
  sellerAccountUrl: '',
  orders: [],
};
