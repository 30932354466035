import React, { useContext } from "react";
import { emptyUser } from "../interfaces/user";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

const AppStateContext = React.createContext({} as any);

export function useAppState() {
  return useContext(AppStateContext);
}

export function AppStateProvider({ children }: any) {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(() => {
    const user = JSON.parse(localStorage.getItem("user") as any);
    if (user) {
      setLoggedIn(true);

      return user;
    }
    setLoggedIn(false);
    return emptyUser;
  });
  

  const authLink = setContext((_, { headers }) => {
    let user = JSON.parse(localStorage.getItem("user") as any);

    if (user)
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${user.token}`,
        },
      };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `GraphQL error: ${message}, locations: ${JSON.stringify(
            locations
          )}, path: ${path}`
        )
      );

      for (let err of graphQLErrors) {
        if (err.extensions) {
          switch (err.extensions.code) {
            case "UNAUTHENTICATED":
              setCurrentUser(emptyUser);
          }
        }
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);

      if (typeof window !== "undefined" && !window.navigator.onLine) {
        console.log("No internet connection");
      }
    }
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      errorLink,
      authLink,
      new HttpLink({ uri: process.env.REACT_APP_API_URL }),
    ]),
  });

  React.useEffect(() => {
    if (currentUser.token) {
      localStorage.setItem("user", JSON.stringify(currentUser));
      setLoggedIn(true);
    } else {
      localStorage.removeItem("user");
      setLoggedIn(false);
    }
  }, [currentUser]);

  return (
    <ApolloProvider client={client}>
      <AppStateContext.Provider
        value={{ currentUser, loggedIn, setCurrentUser }}
      >
        {children}
      </AppStateContext.Provider>
    </ApolloProvider>
  );
}
