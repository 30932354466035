import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Nav from "./components/Nav";
import Home from "./components/Home";
import { Grid, makeStyles, Paper } from "@material-ui/core";

import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Profile from "./components/Profile";

import { useAppState } from "./components/AppStateContext";
import NewPost from "./components/NewPost";

const useStyles = makeStyles({
  fullView: {
    width: "100vw",
    height: "100%"
  },
});

const App: React.FC = () => {
  const classes = useStyles();
  const { loggedIn, currentUser } = useAppState();

  return (
    <Paper className={classes.fullView}>
      <Grid container direction="column">
        <Grid item>
          <Nav />
        </Grid>
        <Grid item>
          <Switch>
            <Route exact path="/">
              {loggedIn ? <Home /> : <Login />}
            </Route>
            <Route exact path="/newpost">
              {loggedIn ? <NewPost /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/sign-up">
              <SignUp />
            </Route>
            <Route exact path="/stripe-complete">
              <Redirect to={`/${currentUser}`} />;
            </Route>
            <Route exact path="/stripe-reauth">
              {
                //this._store.dispatch(onboardUser());
              }
            </Route>
            <Route exact path="/:username">
              <Profile />
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default App;
