import React from "react";
import { useMutation } from "@apollo/client";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useHistory } from "react-router";
import { createOrderMutation } from "../graphql";
import { Post } from "../interfaces/post";
import { InitialOrder } from "../interfaces/stripe";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  Typography,
} from "@material-ui/core";



interface ContainerProps {
  data: Post;
  open: boolean;
  onClose: () => void;
}

const StripeCheckout: React.FC<ContainerProps> = ({ data, open, onClose }) => {
  const history = useHistory();
  const elements = useElements();
  const stripe = useStripe();
  const cardElement = elements?.getElement(CardElement);
  const [cardError, setCardError] = React.useState("");
  const [createOrder, { loading }] = useMutation(createOrderMutation, {
    async update(_, result) {
      const intent: InitialOrder = (result as any).createOrder;
      if (cardElement) {
        const res = await stripe?.confirmCardPayment(intent.intentSecret, {
          payment_method: {
            card: cardElement,
          },
        });

        if (res?.error) {
          setCardError((res?.error && res?.error.message) as string);
        } else {
          history.push(`/receipt/${intent.orderID}`);
        }
      }
    },
    onError(err) {
      if (err.graphQLErrors[0].extensions)
        setCardError(err.graphQLErrors[0].extensions.errors);
    },
  });

  function createToken() {
    setCardError("");
    createOrder({
      variables: {
        postID: data.id,
      },
    });
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Card>
        <CardHeader
          style={{ marginBottom: "20px" }}
          title={<Typography>Purchase {data.heading}</Typography>}
          subheader={data.price}
        />
        <CardContent>
          <h2>Payment</h2>
          <form>
            <div className="paymentBox">
              <CardElement />
              {loading ? <CircularProgress /> : <Typography color="error">{cardError}</Typography>}
              <Button
                variant="contained"
                disabled={loading}
                color="primary"
                onClick={createToken}
              >
                <Typography>Pay</Typography>
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default StripeCheckout;
