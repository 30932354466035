import Feed from "./Feed";
import { emptyUser } from "../interfaces/user";

import {
  Grid,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import {
  getProfileFeedQuery,
  getUserInfoQuery,
  isFollowingQuery,
} from "../graphql";
import { useAppState } from "./AppStateContext";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  profilepic: {
    width: "150px",
    borderRadius: "50%",
  },
});

const Profile: React.FC = () => {
  const { currentUser, loggedIn, setCurrentUser } = useAppState();
  const [profileUser, setProfileUser] = React.useState(emptyUser);
  const { username } = useParams<{ username: string }>();
  const [following, setFollowing] = React.useState(false);

  const classes = useStyles();

  const {
    error: isFollowingError,
    loading: isFollowingLoading,
    data: isFollowingData,
  } = useQuery(isFollowingQuery, {
    variables: {
      username,
    },
  });

  React.useEffect(() => {
    if (isFollowingData && !isFollowingError) {
      setFollowing(isFollowingData.isFollowing);
    }
  }, [isFollowingData, isFollowingError]);

  const {
    error: userInfoError,
    loading: userInfoLoading,
    data: userInfoData,
  } = useQuery(getUserInfoQuery, {
    variables: {
      username,
    },
  });

  React.useEffect(() => {
    if (userInfoData && !userInfoError) {
      setProfileUser(userInfoData.getUserInfo);
    }
  }, [userInfoData, userInfoError]);

  const {
    error,
    loading,
    data: postData,
    fetchMore,
  } = useQuery(getProfileFeedQuery, { variables: { username } });

  function sellerOnboard() {}

  function followUser() {}

  function logout() {
    setCurrentUser(emptyUser);
  }

  return userInfoLoading || isFollowingLoading ? (
    <CircularProgress />
  ) : (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        spacing={10}
        style={{ padding: "3%", marginBottom: "25px" }}
      >
        <Grid item>
          <Grid item>
            <img
              src={profileUser.profileimg}
              className={classes.profilepic}
              alt="Profile"
            />
          </Grid>
          {username === currentUser.username && !currentUser.seller ? (
            <Button variant="contained" onClick={sellerOnboard}>
              Become a Seller
            </Button>
          ) : null}
        </Grid>
        <Grid item>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <h1>{username}</h1>
            {username !== currentUser.username && loggedIn ? (
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "8px" }}
                onClick={followUser}
              >
                {following ? <span>Unfollow</span> : <span>Follow</span>}
              </Button>
            ) : null}
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Typography style={{ marginRight: "10px" }}>
              {profileUser.postCount} posts
            </Typography>
            <Typography style={{ marginRight: "10px" }}>
              {profileUser.followerCount} followers
            </Typography>
            <Typography style={{ marginRight: "10px" }}>
              {profileUser.followingCount} following
            </Typography>
          </Grid>
          {username === currentUser.username ? (
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="contained"
                style={{ marginLeft: "8px" }}
                component={Link}
                to="/newpost"
              >
                Post
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: "8px" }}
                component={Link}
                to="/orders"
              >
                Orders
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: "8px" }}
                onClick={logout}
              >
                Logout
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item>
        {loading ? (
          <CircularProgress />
        ) : username !== currentUser.username ? (
          <Feed
            posts={postData && !error ? postData.getProfileFeed : []}
            feedType="profileuserfeed"
          ></Feed>
        ) : (
          <Feed
            posts={postData && !error ? postData.getProfileFeed : []}
            feedType="profilefeed"
          ></Feed>
        )}
      </Grid>
    </Grid>
  );
};

export default Profile;
