import React from "react";
import Feed from "./Feed";

import { CircularProgress, Grid } from "@material-ui/core";
import { getFeedQuery } from "../graphql";
import { useQuery } from "@apollo/client";

const Home: React.FC = () => {
  const { loading, data } = useQuery(getFeedQuery);

  return (
    <Grid container alignContent="center">
      {loading ? (
        <CircularProgress />
      ) : (
        <Feed feedType="feed" posts={data ? data.getFeed : []}></Feed>
      )}
    </Grid>
  );
};

export default Home;
