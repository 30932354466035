import React from "react";
import { useLazyQuery } from "@apollo/client";
import { loginQuery } from "../graphql";

import { Grid, TextField, Button } from "@material-ui/core";
import { useAppState } from "./AppStateContext";
import { Link } from "react-router-dom";

interface LoginInputs {
  email: string;
  username: string;
  phonenum: string;
  password: string;
}

const Login: React.FC = () => {
  const { setCurrentUser } = useAppState();

  const [formValues, setFormValues] = React.useState<LoginInputs>({
    email: "",
    username: "",
    phonenum: "",
    password: "",
  });

  const [loginUser] = useLazyQuery(loginQuery, {
    onCompleted: (data) => {
      setCurrentUser(data.auth);
    },
    onError: (err) => {
      try {
        if (err.graphQLErrors[0].extensions)
          setValidationErrors(err.graphQLErrors[0].extensions.errors);
      } catch {
        console.log("Unknown error.");
      }
    },
  });

  const [validationErrors, setValidationErrors] = React.useState<LoginInputs>({
    email: "",
    username: "",
    phonenum: "",
    password: "",
  });

  const phoneregx: RegExp =
    /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/;
  const emailregx: RegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  function formInputChange(e: any) {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  function loginUserSumbit() {
    if (emailregx.test(formValues.username)) {
      setFormValues({
        ...formValues,
        email: formValues.username,
        username: "",
      });
    } else if (phoneregx.test(formValues.username)) {
      setFormValues({
        ...formValues,
        phonenum: formValues.username,
        username: "",
      });
    }

    loginUser({
      variables: {
        username: formValues.username,
        email: formValues.email,
        phonenum: formValues.phonenum,
        password: formValues.password,
      },
    });
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: "150px" }}
    >
      <Grid item container alignItems="center" direction="column">
        <h2 style={{ visibility: "hidden" }}>Login Form</h2>
        <Grid item>
          <img
            src="assets/img/logo_w.png"
            style={{ width: "170px", height: "100%" }}
            alt="TakeOff logo"
          />
        </Grid>
        <Grid item container direction="column" xs={12} sm={6} md={4}>
          <form style={{ padding: "20px" }} noValidate>
            <Grid item>
              <TextField
                autoFocus
                fullWidth
                error={validationErrors.username ? true : false}
                label="Username, Email, or Phone number"
                helperText={validationErrors.username}
                name="username"
                value={formValues.username}
                onChange={formInputChange}
              />
            </Grid>
            <Grid item>
              <TextField
                error={validationErrors.password ? true : false}
                fullWidth
                label="Password"
                helperText={validationErrors.password}
                name="password"
                type="password"
                value={formValues.password}
                onChange={formInputChange}
              />
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              style={{ paddingTop: "20px" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={loginUserSumbit}
              >
                Log In
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: "10px" }}>
        <Grid item>
          <Button color="secondary" component={Link} to="forgot">
            Forgot your email or password?
          </Button>
        </Grid>
        <Grid item container direction="row" alignContent="center">
          <p>Don't have an account?</p>
          <Button color="primary" component={Link} to="/sign-up">
            Sign up
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
