import { AppBar, Toolbar, Hidden, Button, Icon, List } from "@material-ui/core";
import { Link } from "react-router-dom";

import { useAppState } from "./AppStateContext";
import { LoSearch } from "@lolumi/common";

const Nav: React.FC = () => {
  const { currentUser, loggedIn } = useAppState();

  function onToggleSidernav() {}

  return (
    <>
      <AppBar color="primary">
        <Toolbar color="primary">
          <Hidden smUp>
            <Button onClick={onToggleSidernav}>
              <Icon>menu</Icon>
            </Button>
          </Hidden>
          <Hidden xsDown>
            <Button component={Link} to="/">
              <img
                src="assets/img/logo.png"
                alt="TakeOff logo"
                style={{ width: "30px" }}
              />
            </Button>
            <LoSearch searchAction={(e: any) => {}} />
            {loggedIn ? (
              <>
                <Button component={Link} to="#">
                  Activity
                </Button>
                <Button component={Link} to={currentUser.username}>
                  <Icon>perm_identity</Icon>
                </Button>
              </>
            ) : null}
            {loggedIn ? null : (
              <>
                <Button component={Link} to="/">
                  Log In
                </Button>
                <Button component={Link} to="/sign-up">
                  Sign Up
                </Button>
              </>
            )}
          </Hidden>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default Nav;
