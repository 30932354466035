import React from "react";
import { useDropzone } from "react-dropzone";
import { Button, Icon, makeStyles } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles({
  dropzone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minWidth: "80vw",
    fontWeight: 200,
    height: "200px",
    border: (props: any) =>
      props.isDragActive ? "2px solid #F44336" : "2px dashed #FF4081",
    borderRadius: "5px",
    background: "#404040",
    margin: "10px 0",
    backgroundColor: (props: any) =>
      props.isDragActive ? "rgba(150,0,0,.2)" : "",
  },
});

export interface DropItem {
  id: string;
  file: File;
}

interface ContainerProps {
  name?: string;
  setFiles: (files: DropItem[], name?: string) => void;
  removeFile: (id: string) => void;
  noClick?: boolean;
}

const Dropzone: React.FC<ContainerProps> = ({
  name,
  setFiles,
  removeFile,
  noClick,
}) => {
  const [dropFiles, setDropFiles] = React.useState<DropItem[]>([]);

  const onHandleDrop = React.useCallback((acceptedFiles: File[]) => {
    const drops:DropItem[] = acceptedFiles.map((file) => (
      {
        id: uuidv4(),
        file
      }
    ))
    setDropFiles((dropFiles) => [...dropFiles, ...drops]);

    if (name) setFiles(drops, name);
    else setFiles(drops);
  }, []);

  function onHandleRemove(id: string) {
    const idx = dropFiles.findIndex((element) => element.id === id);
    setDropFiles((dropFiles) => [
      ...dropFiles.slice(0, idx),
      ...dropFiles.slice(idx + 1),
    ]);
    removeFile(id);
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onHandleDrop,
    noClick,
  });

  const classes = useStyles({ isDragActive });

  return (
    <div>
      <div className={classes.dropzone} {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag and Drop a File</p>
      </div>
      {dropFiles.map((item) => (
        <Button
          key={item.id}
          variant="contained"
          color="secondary"
          startIcon={<Icon>delete</Icon>}
          onClick={() => onHandleRemove(item.id)}
        >
          {item.file.name}
        </Button>
      ))}
    </div>
  );
};

export default Dropzone;
