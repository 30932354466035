import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { useMutation } from "@apollo/client";
import Moment from "react-moment";
import StripeCheckout from "./StripeCheckout";
import { Link } from "react-router-dom";
import { visuallyHidden } from "@material-ui/utils";
import { Post } from "../interfaces/post";
import { useAppState } from "./AppStateContext";
import "swiper/swiper-bundle.css";

import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Icon,
  CardMedia,
  Typography,
  makeStyles,
} from "@material-ui/core";

import {
  createCommentMutation,
  deleteCommentMutation,
  likePostMutation,
} from "../graphql";

const useStyles = makeStyles({
  postcard: {
    position: "relative",
    width: "80vw",
  },
  slidevideo: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  slideimg: {
    margin: "0",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
  },
  nofeed: {
    height: "300px",
  },
  visuallyHidden: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: "1px",
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: "1px"
  }
});

SwiperCore.use([Navigation, Pagination]);

interface ContainerProps {
  posts: Post[];
  feedType: string;
}

const Feed: React.FC<ContainerProps> = ({ feedType, posts }) => {
  const [openCheckout, setOpenCheckout] = React.useState(false);
  const classes = useStyles();
  let videoPlayer = React.useRef<HTMLVideoElement>(null);
  const { currentUser } = useAppState();

  const [likePost, { loading: likePostLoading }] = useMutation(
    likePostMutation,
    {
      update(_, result) {
        console.log(result);
      },
      onError(err) {
        if (err.graphQLErrors[0].extensions)
          console.log(err.graphQLErrors[0].extensions.errors);
      },
    }
  );

  const [createComment, { loading: createCommentLoading }] = useMutation(
    createCommentMutation,
    {
      update(_, result) {
        console.log(result);
      },
      onError(err) {
        if (err.graphQLErrors[0].extensions)
          console.log(err.graphQLErrors[0].extensions.errors);
      },
    }
  );

  const [deleteComment, { loading: deleteCommentLoading }] = useMutation(
    deleteCommentMutation,
    {
      update(_, result) {
        console.log(result);
      },
      onError(err) {
        if (err.graphQLErrors[0].extensions)
          console.log(err.graphQLErrors[0].extensions.errors);
      },
    }
  );

  const [formValues, setFormValues] = React.useState({
    body: "",
  });

  function formInputChange(e: any) {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  function initVideo() {
    const videoEle = videoPlayer.current;

    if (videoEle) videoEle.muted = true;
  }

  function handleCheckoutOpen() {
    setOpenCheckout(true);
  }

  function handleCheckoutClose() {
    setOpenCheckout(false);
  }

  function onLikePost(id: string) {
    likePost({
      variables: {
        postID: id,
      },
    });
  }

  function buyItem(post: Post) {
    //let checkoutRef = this.dialog.open(StripeCheckoutComponent, {
    //  height: '400px',
    //  width: '600px',
    //  data: {
    //    post: post,
    //    user: this.currentUser
    //  }
    //});
  }

  function onCreateComment(postID: string) {
    createComment({ variables: { ...formValues, postID } });
  }

  function onDeleteComment(comment: any) {
    deleteComment({
      variables: {
        postID: comment.postID,
        body: comment.body,
      },
    });
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    >
      {!posts.length
        ? {
            feed: (
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                className={classes.nofeed}
              >
                <img
                  src="assets/img/plane.png"
                  style={{ width: "100px" }}
                  alt="TakeOff Plane"
                />
                <h1>Your Feed</h1>
                <p>
                  No posts to display, your feed will show up here after you've
                  followed some people
                </p>
              </Grid>
            ),
            profilefeed: (
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                className={classes.nofeed}
              >
                <img
                  src="assets/img/plane.png"
                  style={{ width: "100px" }}
                  alt="TakeOff Plane"
                />
                <h1>Share Photos and Videos</h1>
                <p>
                  When you share photos and videos, they'll appear on your
                  profile
                </p>
                <Button
                  variant="contained"
                  style={{ marginLeft: "8px" }}
                  component={Link}
                  to="/newpost"
                >
                  Post
                </Button>
              </Grid>
            ),
            profileuserfeed: (
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                className={classes.nofeed}
              >
                <img
                  src="assets/img/plane.png"
                  style={{ width: "100px" }}
                  alt="TakeOff Plane"
                />
                <h1>Profile</h1>
                <p>This user has no posts yet</p>
              </Grid>
            ),
          }[feedType]
        : posts.map((post: Post) => (
            <Grid
              item
              key={post.id}
              style={{ marginBottom: "10px", padding: "0" }}
            >
              <Card className={classes.postcard}>
                <CardHeader
                  style={{ padding: "10px", marginBottom: "20px" }}
                  avatar={
                    <Avatar>
                      <img src={currentUser.profileimg} alt="Profile" />
                    </Avatar>
                  }
                  title={
                    <Button
                      color="primary"
                      component={Link}
                      to={`/${post.user.username}`}
                    >
                      <h1>{post.user.username}</h1>
                    </Button>
                  }
                  subheader={post.heading}
                />
                <CardContent style={{ padding: 0 }}>
                  <Swiper
                    tag="section"
                    wrapperTag="ul"
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    pagination
                  >
                    {post.postMedia.map((media, i) => (
                      <SwiperSlide tag="li" key={media.fileName}>
                        {
                          {
                            "image/jpeg": (
                              <CardMedia
                                className={classes.slideimg}
                                image={media.fileName}
                                title="picture"
                              />
                            ),
                            "video/quicktime": (
                              <video
                                ref={videoPlayer}
                                className={classes.slidevideo}
                                onCanPlay={initVideo}
                                src={media.fileName}
                                muted
                                autoPlay
                                loop
                                playsInline
                              ></video>
                            ),
                          }[media.mediaType]
                        }
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </CardContent>
                <CardContent>
                  <div>
                    <Button onClick={() => onLikePost(post.id)}>
                      {post.isLiked ? (
                        <Icon>favorite</Icon>
                      ) : (
                        <Icon>favorite_border</Icon>
                      )}
                    </Button>
                    {post.selling ? (
                      <>
                        <Button onClick={handleCheckoutOpen}>
                          <Icon>shop</Icon>
                        </Button>
                        <StripeCheckout
                          data={post}
                          open={openCheckout}
                          onClose={handleCheckoutClose}
                        />
                      </>
                    ) : null}
                  </div>
                </CardContent>
                <CardContent>
                  <p>{post.body}</p>
                </CardContent>
                {post.comments.map((comment) => (
                  <CardContent key={comment.id}>
                    <p>
                      <strong>{comment.user.username}</strong> {comment.body}
                    </p>
                  </CardContent>
                ))}
                <CardContent>
                  <Typography>
                    <small>
                      <Moment date={parseInt(post.createdAt)} durationFromNow />
                    </small>
                  </Typography>
                </CardContent>
                <CardContent>
                  <form>
                    <Typography variant="h2" style={visuallyHidden}>
                      Comment Form
                    </Typography>
                    <TextField
                      id="body"
                      value={formValues.body}
                      onChange={formInputChange}
                    />
                    <Button onClick={() => onCreateComment(post.id)}>
                      Post
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          ))}
    </Grid>
  );
};

export default Feed;
