import { useMutation } from "@apollo/client";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { registerMutation } from "../graphql";
import { RegisterInput, registerInputDefault } from "../interfaces/user";

interface ContainerProps {}

const SignUp: React.FC<ContainerProps> = () => {
  const [registerUser, { loading }] = useMutation(registerMutation, {
    update(_, result) {
      console.log(result);
    },
    onError(err) {
      if (err.graphQLErrors[0].extensions)
        setValidationErrors(err.graphQLErrors[0].extensions.errors);
    },
  });

  const [formValues, setFormValues] =
    React.useState<RegisterInput>(registerInputDefault);

  const [validationErrors, setValidationErrors] =
    React.useState<RegisterInput>(registerInputDefault);

  function handleInputChange(e: any) {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  function signupUserSubmit() {
    registerUser({ variables: { registerInput: formValues } });
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: "100px" }}
    >
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        xs={12}
        sm={6}
        md={4}
      >
        <Grid item>
          <img
            src="assets/img/logo_w.png"
            style={{ width: "170px", height: "100%" }}
            alt="TakeOff logo"
          />
        </Grid>
        <form
          style={{ padding: "20px" }}
          noValidate
        >
          <h2 style={{ visibility: "hidden" }}>Sign Up Form</h2>

          <Grid item>
            <TextField
              error={validationErrors.username ? true : false}
              fullWidth
              name="username"
              label="Username"
              helperText={validationErrors.username}
              value={formValues.username}
              onChange={handleInputChange}
            />
          </Grid>
          <TextField
            error={validationErrors.email ? true : false}
            fullWidth
            name="email"
            label="Email"
            helperText={validationErrors.email}
            value={formValues.email}
            onChange={handleInputChange}
          />
          <TextField
            error={validationErrors.password ? true : false}
            fullWidth
            name="firstName"
            label="First Name"
            helperText={validationErrors.firstName}
            value={formValues.firstName}
            onChange={handleInputChange}
          />
          <TextField
            error={validationErrors.lastName ? true : false}
            fullWidth
            name="lastName"
            label="Last Name"
            helperText={validationErrors.lastName}
            value={formValues.lastName}
            onChange={handleInputChange}
          />
          <TextField
            error={validationErrors.dateOfBirth ? true : false}
            name="dateOfBirth"
            label="Date Of Birth"
            type="date"
            helperText={validationErrors.dateOfBirth}
            value={formValues.dateOfBirth}
            fullWidth
            onChange={handleInputChange}
          />
          <TextField
            error={validationErrors.password ? true : false}
            fullWidth
            name="password"
            label="Password"
            helperText={validationErrors.password}
            value={formValues.password}
            type="password"
            onChange={handleInputChange}
          />
          <TextField
            error={validationErrors.confirmPassword ? true : false}
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            helperText={validationErrors.confirmPassword}
            value={formValues.confirmPassword}
            type="password"
            onChange={handleInputChange}
          />
          <TextField
            error={validationErrors.phonenum ? true : false}
            fullWidth
            name="phonenum"
            label="Phone Number"
            helperText={validationErrors.phonenum}
            value={formValues.phonenum}
            defaultValue="(xxx) xxx-xxxx"
            onChange={handleInputChange}
          />
          <TextField
            value={formValues.gender}
            defaultValue="Male"
            name="gender"
            label="Gender"
            select
            onChange={handleInputChange}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Rather not say">Rather not say</MenuItem>
          </TextField>
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            style={{ marginBottom: "10px" }}
          >
            <FormControl error={validationErrors.seller ? true : false}>
              <FormControlLabel
                name="seller"
                label="Seller Account"
                control={
                  <Switch defaultValue="false" value={formValues.seller} />
                }
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" justifyContent="center">
            <Button variant="contained" color="primary" onClick={signupUserSubmit}>
              Create Account
            </Button>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "10px" }}
          >
            <Typography>Already got an account?</Typography>
            <Button color="primary" component={Link} to="/">
              <Typography>Login here</Typography>
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default SignUp;
