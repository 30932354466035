import React from "react";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";

import { createPostMutation } from "../graphql";
import { useMutation } from "@apollo/client";
import { Postmedia } from "../interfaces/post";
import Dropzone, { DropItem } from "./Dropzone";
import { useAppState } from "./AppStateContext";
import { useHistory } from "react-router";

interface PostInputs {
  private: boolean;
  heading: string;
  body: string;
  postMedia: Postmedia[];
  topics: string;
  selling: boolean;
  price: number;
}

interface ContainerProps {}

const NewPost: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const { currentUser } = useAppState();
  const [mediaFiles, setMediaFiles] = React.useState<DropItem[]>([]);

  const [createPost, { loading }] = useMutation(createPostMutation, {
    update(_, result) {
      const post = (result as any).createPost;
      mediaFiles.forEach(async (item: DropItem, i: number) => {
        const httpOptions = {
          method: "PUT",
          headers: {
            "Content-Type": item.file.type,
          },
          body: item.file,
        };

        await fetch(post.mediaTokens[i], httpOptions);
      });

      history.push(`/${currentUser.username}`);
    },
    onError(err) {
      if (err.graphQLErrors[0].extensions)
        setValidationErrors(err.graphQLErrors[0].extensions.errors);
    },
  });

  const [formValues, setFormValues] = React.useState<PostInputs>({
    private: false,
    heading: "",
    body: "",
    postMedia: [],
    topics: "",
    selling: false,
    price: 0,
  });

  const [validationErrors, setValidationErrors] = React.useState({
    body: "",
    heading: "",
    price: "",
  });

  function handleDrop(files: DropItem[], name?: string) {
    const newMedia: Postmedia[] = files.map((item) => ({
      fileName: item.file.name,
      mediaType: item.file.type,
      preview: name === "preview" ? true : false,
    }));

    setFormValues({
      ...formValues,
      postMedia: [...formValues.postMedia, ...newMedia],
    });
    setMediaFiles((mediaFiles) => [...mediaFiles, ...files]);
  }

  function handleRemove(id: string) {
    const idx = mediaFiles.findIndex((element) => element.id === id);
    setMediaFiles((mediaFiles) => [
      ...mediaFiles.slice(0, idx),
      ...mediaFiles.slice(idx + 1),
    ]);

    setFormValues({
      ...formValues,
      postMedia: [
        ...formValues.postMedia.slice(0, idx),
        ...formValues.postMedia.slice(idx + 1),
      ],
    });
  }

  function formInputChange(e: any) {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  }

  function switchInputChange(e: any) {
    const { name, checked } = e.target;

    setFormValues({
      ...formValues,
      [name]: checked,
    });
  }

  function newPost() {
    createPost({ variables: formValues });
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h6">New Post</Typography>

      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <form>
          <FormControlLabel
            control={
              <Switch
                name="private"
                checked={formValues.private}
                onChange={switchInputChange}
              />
            }
            label="Private"
          />
          <Divider />
          <Dropzone
            name="main"
            setFiles={handleDrop}
            removeFile={handleRemove}
          />
          <TextField
            autoFocus
            fullWidth
            error={validationErrors.heading ? true : false}
            label={formValues.selling ? "Heading" : "Heading (Optional)"}
            helperText={validationErrors.heading}
            name="heading"
            value={formValues.heading}
            onChange={formInputChange}
          />

          <TextField
            autoFocus
            fullWidth
            multiline
            maxRows={3}
            error={validationErrors.heading ? true : false}
            label="Caption"
            helperText={validationErrors.body}
            name="body"
            value={formValues.body}
            onChange={formInputChange}
          />
          <Grid
            item
            container
            justifyContent="center"
            style={{ marginBottom: "10px" }}
          >
            <FormControlLabel
              label="Selling"
              control={
                <Switch
                  name="selling"
                  checked={formValues.selling}
                  onChange={switchInputChange}
                />
              }
            />
          </Grid>
          <Divider />
          {formValues.selling ? (
            <>
              <Dropzone
                name="preview"
                setFiles={handleDrop}
                removeFile={handleRemove}
              />
              <TextField
                autoFocus
                fullWidth
                multiline
                maxRows={3}
                error={validationErrors.price ? true : false}
                label="Price"
                helperText={validationErrors.price}
                name="price"
                type="number"
                value={formValues.price}
                onChange={formInputChange}
              />
            </>
          ) : null}
        </form>
      </Grid>
      <Button variant="contained" color="primary" onClick={newPost}>
        <Typography>Post</Typography>
      </Button>
    </Grid>
  );
};

export default NewPost;
