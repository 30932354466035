import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { AppStateProvider } from "./components/AppStateContext";
import { Elements } from "@stripe/react-stripe-js";
import { indigo, pink } from "@material-ui/core/colors";
import { loadStripe } from "@stripe/stripe-js";

const stripe = loadStripe(
  process.env.REACT_APP_STRIPE_PUBKEY ? process.env.REACT_APP_STRIPE_PUBKEY : ""
);

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: indigo[800],
    },
    secondary: {
      main: pink["A100"],
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppStateProvider>
          <Elements stripe={stripe}>
            <App />
          </Elements>
        </AppStateProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

